import { extractRawStringFromMarkdownField } from '~/helpers/dataHelpers'
import { graphql } from 'gatsby'
import FullBleedChecklist from '~/components/FullBleedChecklist'
import PageWrapper from '~/components/PageWrapper'
import React from 'react'
import SEO from '~/components/SEO'

export default function FeaturePage({ location, data }) {
  const isBusinessServices = location.pathname === '/business-services/'

  const {
    moduleContents,
    name,
    shortDescription,
    bottomModules,
    metaDescription,
    metaKeywords,
    metaTitle,
    metaImage,
  } = data.contentfulFeature

  return (
    <PageWrapper
      path={location.pathname}
      modules={moduleContents}
      bottomModules={bottomModules}
    >
      <SEO
        pathname={location.pathname}
        title={metaTitle || name}
        description={
          metaDescription
            ? extractRawStringFromMarkdownField(metaDescription)
            : extractRawStringFromMarkdownField(shortDescription)
        }
        image={metaImage && metaImage.fluid.src}
        keywords={metaKeywords && metaKeywords}
      />
      {isBusinessServices && <FullBleedChecklist />}
    </PageWrapper>
  )
}

export const featurePageQuery = graphql`
  query featurePageQuery($slug: String) {
    contentfulFeature(slug: { eq: $slug }) {
      name
      shortDescription {
        childMarkdownRemark {
          rawMarkdownBody
        }
      }
      metaTitle
      metaDescription {
        childMarkdownRemark {
          rawMarkdownBody
        }
      }
      metaKeywords
      metaImage {
        fluid(maxWidth: 900, maxHeight: 450, quality: 70) {
          src
        }
      }
      moduleContents {
        __typename
        ...valueProp
        ...collapsibleCollection
      }
      bottomModules {
        __typename
        ...valueProp
        ...bulletCollection
        ...collapsibleCollection
      }
    }
  }
`
