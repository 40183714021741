import { Box, Flex, Heading, Icon, SimpleGrid, Text } from '@hometime-ui/core'
import { MdCheck } from 'react-icons/md'
import Image from './Image'
import React from 'react'
import WidthWrapper from './WidthWrapper'

export default function FullBleedChecklist() {
  const CHECKLIST = [
    {
      text: 'What is the ideal technology stack?',
    },
    {
      text: 'Who to hire and when? Staff vs contractors vs offshore?',
    },
    {
      text: 'How to streamline cleaning operations?',
    },
    {
      text: 'What are my levers to increase profitability?',
    },
  ]

  return (
    <Box py={{ base: 10, md: 20 }}>
      <WidthWrapper>
        <Flex
          h={{ base: 'auto', lg: '558px' }}
          alignItems="center"
          w="100%"
          position="relative"
          py="10"
          px={{ base: 4, lg: 8 }}
          borderRadius="md"
          overflow="hidden"
          className="Top"
        >
          <Image relativePath="checklist-image.png" />
          <Flex
            flexDirection="column"
            bg="white"
            py="8"
            px="6"
            position="relative"
            zIndex="1"
            borderRadius="md"
            w={{ lg: '630px' }}
          >
            <Heading level="2" mb="4">
              Operational blueprint
            </Heading>
            <Text color="gray.700">
              More revenue, more profit and more time with less stress. Our team
              has been on the journey from ten homes to thousands and they have
              the playbook to help you optimise your business.
            </Text>
            <SimpleGrid
              as="ul"
              listStyleType="none"
              alignItems="center"
              my="4"
              columns="1"
              spacing="2"
            >
              {CHECKLIST.map((item, i) => (
                <Flex as="li" key={i}>
                  <Icon as={MdCheck} h="6" w="6" mr="2" color="teal.200" />
                  <Text color="gray.700">{item.text}</Text>
                </Flex>
              ))}
            </SimpleGrid>
            <Text color="gray.700">
              Our team will help you implement a proven playbook for success.
            </Text>
          </Flex>
        </Flex>
      </WidthWrapper>
    </Box>
  )
}
